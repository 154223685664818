var columns = [{
  title: '用户ID',
  dataIndex: 'consumeuid',
  key: 'consumeuid',
  width: '140px',
  scopedSlots: {
    customRender: 'consumeuid'
  }
}, {
  title: '用户昵称',
  dataIndex: 'consumenickname',
  key: 'consumenickname',
  width: '100px',
  scopedSlots: {
    customRender: 'consumenickname'
  }
}, {
  title: '消费金额',
  dataIndex: 'consumeamount',
  key: 'consumeamount',
  width: '100px',
  scopedSlots: {
    customRender: 'consumeamount'
  }
}, {
  title: '消费类型',
  dataIndex: 'consumetype',
  key: 'consumetype',
  width: '120px',
  scopedSlots: {
    customRender: 'consumetype'
  }
}, {
  title: '收益方ID',
  dataIndex: 'benefituid',
  key: 'benefituid',
  width: '140px',
  scopedSlots: {
    customRender: 'benefituid'
  }
}, {
  title: '收益方昵称',
  dataIndex: 'benefitnickname',
  key: 'benefitnickname',
  width: '100px',
  scopedSlots: {
    customRender: 'benefitnickname'
  }
}, {
  title: '时间',
  dataIndex: 'createtime',
  key: 'createtime',
  width: '160px',
  scopedSlots: {
    customRender: 'createtime'
  }
}];
export { columns };