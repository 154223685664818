var searchKeys = [{
  key: "userId",
  label: "用户ID",
  placeholder: "请输入用户ID",
  required: false,
  rules: [],
  input: true
}, {
  key: "timeRange",
  label: "时间",
  format: "YYYY-MM-DD HH:mm",
  placeholder: ["开始时间", "结束时间"],
  rules: [],
  dates: true
}, {
  key: "consumptionType",
  label: "消费类型",
  placeholder: "请选择消费类型",
  required: false,
  rules: [],
  select: true,
  initialValue: ''
}, {
  key: "button",
  label: "",
  placeholder: "",
  button: true
}];
export { searchKeys };